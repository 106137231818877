<template>
  <div id="bg">
    <div class="hui">
      <el-form ref="form" :model="form" label-width="80px" size="small">
        <el-tabs v-model="activeName">
          <el-tab-pane label="基础设置" name="first">
            <el-form-item label="AI总开关">
              <el-switch active-value="1" inactive-value="0" v-model="form.ai_open"></el-switch>
            </el-form-item>
            <el-form-item label="图片换脸开关">
              <el-switch active-value="1" inactive-value="0" v-model="form.ai_image_open"></el-switch>
              金额:<el-input v-model="form.ai_image_price" class="width_100" placeholder="自定义模板金额"></el-input>
            </el-form-item>
            <el-form-item label="图片换脸自定义模板">
              <el-switch active-value="1" inactive-value="0" v-model="form.ai_image_temp_open"></el-switch>
              金额:<el-input v-model="form.ai_image_temp_price" class="width_100" placeholder="自定义模板金额"></el-input>
            </el-form-item>
            <el-form-item label="视频换脸自定义模板">
              <el-switch active-value="1" inactive-value="0" v-model="form.ai_video_open"></el-switch>
              金额:<el-input v-model="form.ai_video_price" class="width_100" placeholder="自定义模板金额"></el-input> 按每10秒计算. 60秒视频金额={{6*form.ai_video_price}}
            </el-form-item>
            <el-form-item label="AI脱衣">
              <el-switch active-value="1" inactive-value="0" v-model="form.ai_ty_open"></el-switch>
              金额:<el-input v-model="form.ai_ty_price" class="width_100" placeholder="自定义模板金额"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="接口设置">
            <el-form-item label="[AKOOL]clientId">
              <el-input v-model="form.akool_client_id" class="width_400" placeholder="akool_client_id"></el-input>
            </el-form-item>
            <el-form-item label="[AKOOL]clientSecret">
              <el-input v-model="form.akool_client_secret" class="width_400" placeholder="akool_client_secret"></el-input>
            </el-form-item>
            <el-form-item label="[AKOOL]userId">
              <el-input v-model="form.akool_user_id" class="width_400" placeholder="akool_user_id"></el-input>
            </el-form-item>
            <el-form-item label="[AKOOL]回调地址">
              <el-input v-model="form.akool_webhook_url" class="width_400" placeholder="clothoff_api_key"></el-input>
            </el-form-item>
            <el-form-item label="[ClothOff]APIKEY">
              <el-input v-model="form.clothoff_api_key" class="width_400" placeholder="clothoff_api_key"></el-input>
            </el-form-item>
            <el-form-item label="[ClothOff]回调地址">
              <el-input v-model="form.clothoff_webhook_url" class="width_400" placeholder="clothoff_webhook_url"></el-input>
            </el-form-item>
            <el-form-item label="S3图片域名">
              <el-input v-model="form.akool_s3_url" class="width_400" placeholder="akool_s3_url"></el-input>
            </el-form-item>
            <el-form-item label="S3视频域名">
              <el-input v-model="form.akool_s3_video_url" class="width_400" placeholder="akool_s3_url"></el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="medium">保存设置</el-button>
        </el-form-item>
      </el-form>
      <div>

      </div>
    </div>
  </div>
</template>

<script>
// import httpClient from "@/config/httpClient";
import httpClient from "@/config/httpClient";

export default {
  name: "ai_setting",
  components: {
  },
  data() {
    return {
      activeName:"first",
      form: {
        ai_image_temp_open:0, // 自定义模板开关
        ai_image_temp_price:0,// 自一定模板金额
        ai_image_price:0, // 图片换脸模板金额
        ai_open:0, // 总开关
        ai_image_open:0, // 图片换脸模板开关
        ai_ty_open:0, // 脱衣开关
        ai_video_open:0, // 视频换脸开关
        ai_video_price:0, // 视频换脸金额
        ai_ty_price:0, // 脱衣金额
        akool_client_id:"",
        akool_client_secret:"",
        akool_user_id:"",
        akool_s3_url:"",
        akool_s3_video_url:"",
        akool_webhook_url:"",
        clothoff_api_key:"",
        clothoff_webhook_url:"",
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getList(){
      var keys = []
      for(var key in this.form){
        keys.push(key)
      }
      httpClient("getSystemOption").post({keys:keys.join(",")}).then((res)=>{
        if (res.code == 0){
          for(var key in res.data){
            this.form[key] = res.data[key]
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    onSubmit(){
      var options = []
      for(var key in this.form){
        options.push({
          key:key,
          value:this.form[key],
          show_type:"1",
        })
      }
      httpClient("saveSystemOption").post({options:JSON.stringify(options)}).then((res)=>{
        if (res.code != 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.$message({message:"保存成功",type:"success"})
        }
      })
    }
  },
};
</script>

<style scoped>
.hui {
  padding: 24px;
  background: #f7f8fa;
  height: calc(100% - 48px);
  overflow-x: auto;
}
.hui::-webkit-scrollbar {
  display: none;
}
.hui >>> .el-form-item__label {
  width: 150px !important;
}
.el-form-item__content>.el-input {
  width: 500px;
}

.hui >>> .key .el-input {
  width: 500px;
}
.hui >>> .el-form-item__content {
  margin-left: 30px !important;
}
.hui span {
  color: #606266;
  margin-left: 10px;
}
</style>
